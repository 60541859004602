define('la-grange-a-louis/models/garniture', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    nom: _emberData.default.attr('string'),

    //Relations
    produits: _emberData.default.hasMany('produit'),

    displayName: _ember.default.computed('nom', function () {
      return this.get('nom');
    })
  });
});