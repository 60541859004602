define('la-grange-a-louis/instance-initializers/instance-initializer', ['exports', 'la-grange-a-louis/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {

    var router = app.lookup('router:main');
    var i18n = app.lookup('service:i18n');
    var currentLang = _environment.default.i18n.defaultLocale;
    var path = window.location.pathname;

    var newPath = '';

    var LangFromPath = path.match('^/([a-z]{2})(?:/|$)');

    if (LangFromPath && LangFromPath[1]) {
      var i = _environment.default.i18n.allowedLocales.indexOf(LangFromPath[1]);
      currentLang = i > -1 ? LangFromPath[1] : currentLang;
    }

    newPath = '/' + currentLang + '/';

    router.rootURL = newPath;
    i18n.set('locale', currentLang);

    if (newPath && path.indexOf(newPath) === -1) {
      window.location.pathname = newPath;
    }
  }

  exports.default = {
    name: 'instance-initializer',
    initialize: initialize
  };
});