define('la-grange-a-louis/utils/helpers', ['exports', 'ember-google-maps/utils/helpers'], function (exports, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'computedPromise', {
    enumerable: true,
    get: function () {
      return _helpers.computedPromise;
    }
  });
  Object.defineProperty(exports, 'position', {
    enumerable: true,
    get: function () {
      return _helpers.position;
    }
  });
});