define('la-grange-a-louis/mixins/pagination', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Mixin.create({

		queryParams: {
			page: {
				refreshModel: true,
				as: 'page'
			},
			perPage: {
				refreshModel: true,
				as: 'perPage'
			}
		},
		page: 1,
		perPage: 10,

		actions: {
			getPage: function getPage(page) {
				this.set("page", page);
			}
		}

	});
});