define('la-grange-a-louis/models/produit', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _emberData.default.Model.extend({

      nom: _emberData.default.attr('string'),
      description: _emberData.default.attr('string'),
      prix: _emberData.default.attr('number'),
      rang: _emberData.default.attr('number'),
      taille: _emberData.default.attr('number'),

      //Calculate Attributes
      displayName: _ember.default.computed('nom', function () {
         return this.get('nom');
      }),

      prix_texte: _ember.default.computed('prix', function () {
         return this.get('prix') / 100 + " €";
      }),

      //Relations
      labels: _emberData.default.hasMany('label'),
      tags: _emberData.default.hasMany('tag'),
      fours: _emberData.default.hasMany('four'),
      garnitures: _emberData.default.hasMany('garniture'),
      images: _emberData.default.hasMany('image'),
      grammages: _emberData.default.hasMany('grammage'),

      type: _emberData.default.belongsTo('type-produit')
   });
});