define('la-grange-a-louis/components/loader-g', ['exports', 'ember'], function (exports, _ember) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _ember.default.Component.extend({
      loader: _ember.default.inject.service("loader"),

      visible: _ember.default.computed('loader.visible', function () {

         if (this.get('loader.visible') === false) {
            return "none";
         }

         return "";
      })
   });
});