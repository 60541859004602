define("la-grange-a-louis/locales/fr/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    //Titre
    "titre": "La Grange à Louis",
    "sub-title": "La tradition accompagne la fabrication de mes pains.",
    "cc": "La Grange à Louis - 2018 © Tous droits réservés ",

    //Metier
    "boulanger": "Boulanger",
    "pains": "Pain",
    "viennoiseries": "Viennoiserie",

    //Slogan
    "un_air_de_tradition": "Un air de",

    "tradition": "Tradition",

    //Menu
    "menu": {

      "notre_empreinte": {
        "label": " Notre Empreinte",
        "sub_label": "Comme un air de la madeleine de Proust",

        "contenu": {
          "texte": "Les souvenirs nous accompagnent et nous guident tout au long de notre vie. Pour moi, c\’est retrouver les odeurs de mon enfance pour les faire ressurgir à ceux qui les avaient oubliées et les faire découvrir à ceux qui voudront les garder.      Farine de tradition Française, Levain de pâte, sel de Guérande et eau façonnent le pain que je vous donne en héritage. Ma production est lente car elle prend le temps de vous construire un souvenir."
        }
      },
      "un_air_de_tradition": {
        "label": "Un Air",
        "sub_label": "de Tradition",
        "label_content": "Un Air de Tradition"
      },

      "nos_engagements": {
        "label": "Nos Engagements",
        "sub_label": "Du blé, de l'eau et de la passion",

        "contenu": {

          "label_ble": "Blé",
          "sub_label_ble": "Directement du Paysan-Meunier au Fournil",

          "label_four": "Four",
          "sub_label_four": "La flamme de la tradition",

          "label_pain": "Pains",
          "sub_label_pains": "Une gamme variée de pains"
        }
      },

      "galerie": {
        "label": "Galerie",
        "sub_label": "des Pains"
      },

      "savoir_faire": {
        "label": "Savoir-faire",
        "sub_label": "la tradition accompagne la fabrication de mes pains",

        "contenu": {
          "texte": "L’âme de mon pain trouve sa raison d’être dans la noblesse de la farine utilisée. Produite localement en culture raisonnée, cette farine de terroir approvisionnée en circuit court nous ancre plus profondément dans la tradition boulangère. Le goût du pain est pour moi important. Respecter la qualité des matières, les temps de pose et les modes de cuisson sont les principes de base pour un pain de qualité. Je vous invite donc à partager avec moi ces moments simples près d’un vieux  four à bois.",
          "label_creation": "Créations",
          "sub_label_creation": "originales"
        }
      },

      "contact": {
        "label": "Contact",
        "contenu": {
          "email": "la-grange-a-louis@gmail.com"

        },
        "sub_label": "la tradition accompagne la fabrication de mes pains"
      },
      "footer": {
        "adresse": {
          "label": "Adresse",
          "contenu_adresse": "265 Avenue Du Grésivaudan",
          "contenu_ville": "38660 La Terrasse"
        },
        "email": {
          "label": "Email",
          "contenu": "boulangerie@lagrangealouis.fr"
        },
        "telephone": {
          "label": "Téléphone",
          "contenu": "06 51 01 14 86"
        }

      }
    },

    //Actions
    "actions": {
      "retour": "Retour au site",
      "produits": "Voir mes produits",
      "commander": "Commander"
    }

  };
});