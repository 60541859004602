define('la-grange-a-louis/models/grammage', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _emberData.default.Model.extend({
      gramme: _emberData.default.attr('number'),

      //Relations
      produits: _emberData.default.hasMany('produit'),

      displayName: _ember.default.computed('gramme', function () {
         var gramme = this.get('gramme');
         if (gramme < 1000) {
            return gramme + "g";
         } else {
            gramme = gramme / 1000;
            return gramme + "Kg";
         }
      })
   });
});