define('la-grange-a-louis/models/tag', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _emberData.default.Model.extend({
      texte: _emberData.default.attr('string'),
      background: _emberData.default.attr('string'),
      color: _emberData.default.attr('string'),
      icone: _emberData.default.attr('string'),

      displayName: _ember.default.computed('texte', function () {
         return this.get('texte');
      }),

      style: _ember.default.computed('texte', function () {
         var style = "color:" + this.get('color') + " !important; background-color:" + this.get('background') + " !important;";
         return style.htmlSafe();
      }),

      //Relations
      produits: _emberData.default.hasMany('produit')
   });
});