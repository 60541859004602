define('la-grange-a-louis/components/pagination-page', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({
		data: undefined,
		maxPage: 5,

		actions: {
			getPage: function getPage(page) {
				this.onPageSelect(page);
			}
		}
	});
});