define('la-grange-a-louis/components/form-textarea', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({
		classNameBindings: ['class_parent'],
		filed_name: undefined,
		name: undefined,
		placeholder: undefined,
		class: "",
		require: false,
		row: 3,
		class_parent: undefined

	});
});