/*import Ember from 'ember';
import Uploader from 'ember-uploader/uploaders/uploader';
import ENV from '../../../config/environment';

export default Ember.Controller.extend( {

//TODO add in a parent controller
	session: Ember.inject.service(),
	getHeader:function(header){
		var authorisation = {
			"Content-Type" : "application/json",
	        "Authorization":"Bearer "+this.get('session.data.authenticated.access_token'),
	    };

		return Object.assign(authorisation, header);
	},
	types_produits : Ember.A(),
	selected_type_produit : undefined,

	labels : Ember.A(),
	selected_labels : Ember.A(),

	grammages : Ember.A(),
	selected_grammages: Ember.A(),

	fours : Ember.A(),
	selected_fours: Ember.A(),

	garnitures : Ember.A(),
	selected_garnitures: Ember.A(),

	images_preview : Ember.A(),
	files_images : undefined,
	//images : Ember.A(),
	//selected_images: Ember.A(),

	tags : Ember.A(),
	selected_tags: Ember.A(),

	
	init:function(){
		var _this = this;
		this._super();

		

	    Ember.run.schedule("afterRender",this,function() {
	    	_this.loadTypesProduits();
	    	_this.loadLabels();
	    	_this.loadGrammages();
	    	_this.loadFours();
	    	_this.loadGarnitures();
	    	//_this.loadImages();
	    	_this.loadTags();
	    });

	},

	actions: {


		saveImages:function(){

			
		    var formData = new FormData();
			var blobFile = Ember.$('#file1').files[1];
		    formData.append("fileToUpload", blobFile);

		    Ember.$.ajax({
	      		url: ENV.APP.apiURL+"/admin/produits/1/images",
		       type: "POST",
		       data: formData,
		       processData: false,
		       contentType: false,
		       success: function(response) {
		           // .. do something
		       },
		       error: function(jqXHR, textStatus, errorMessage) {
		           console.log(errorMessage); // Optional
		       }
       		 });

		    return;


			var files = this.get('files_images');
			const uploader = Uploader.create({
				//paramNamespace: 'post'
				paramName: 'file',
			  	url: ENV.APP.apiURL+"/admin/produits/1/images"
			});

			//important
			uploader.set('ajaxSettings', {headers : this.getHeader({})});

			uploader.on('progress', e => {
			  // Handle progress changes
			  // Use `e.percent` to get percentage
			  console.log('progress ' + e.percent + "%");
			});

			uploader.on('didUpload', e => {
			  // Handle finished upload
			  console.log('progredidUploadss');
			});

			uploader.on('didError', (jqXHR, textStatus, errorThrown) => {
			  // Handle unsuccessful upload

			  console.log('didError');
			});

			if (!Ember.isEmpty(files)) {
				
				for(var i = 0 ; i < files.length ; i++){
					
					//uploader.upload(files[i], {});
					var formData = new FormData();
					var blobFile = Ember.$('#file').files[i];
				    formData.append("fileToUpload", blobFile);

				    Ember.$.ajax({
			      		url: ENV.APP.apiURL+"/admin/produits/1/images",
				       type: "POST",
				       data: formData,
				       processData: false,
				       contentType: false,
				       success: function(response) {
				           // .. do something
				       },
				       error: function(jqXHR, textStatus, errorMessage) {
				           console.log(errorMessage); // Optional
				       }
		       		 });
				}
			}
		},
	},

	loadFours : function(){
		var _this = this;

		this.store.query('four' , {}).then(function(fours){
			_this.set('fours', fours);
		});
	},

	loadGarnitures : function(){
		var _this = this;

		this.store.query('garniture' , {}).then(function(garnitures){
			_this.set('garnitures', garnitures);
		});
	},

	

	loadTypesProduits : function(){
		var _this = this;

		this.store.query('type-produit' , {}).then(function(types_produits){
			_this.set('types_produits', types_produits);
		});
	},

	loadLabels : function(){
		var _this = this;

		this.store.query('label' , {}).then(function(labels){
			_this.set('labels', labels);
		});
	},

	loadGrammages: function(){
		var _this = this;

		this.store.query('grammage' , {}).then(function(grammages){
			_this.set('grammages', grammages);
		});
	},

	loadTags: function(){
		var _this = this;

		this.store.query('tag' , {}).then(function(tags){
			_this.set('tags', tags);
		});
	},
});*/
define("la-grange-a-louis/controllers/admin/produits/ajout", [], function () {
	"use strict";
});