define("la-grange-a-louis/helpers/ucfirst", ["exports", "ember"], function (exports, _ember) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ucfirst = ucfirst;
	function ucfirst(params /*, hash*/) {
		if (params[0] !== undefined && typeof params[0] === "string") {
			return params[0].charAt(0).toUpperCase() + params[0].slice(1);
		}
		return params[0];
	}

	exports.default = _ember.default.Helper.helper(ucfirst);
});