define('la-grange-a-louis/routes/boutique', ['exports', 'la-grange-a-louis/routes/routeParent'], function (exports, _routeParent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _routeParent.default.extend({
        model: function model() {
            return this.store.query('produit', {
                include: "labels"
            });
        },


        renderTemplate: function renderTemplate() {
            this._super.apply(this, arguments);

            //Menu
            this.render('boutique/menu', {
                outlet: 'menu',
                into: 'boutique',
                controller: 'boutique'
            });

            //Header
            this.render('boutique/filters', {
                outlet: 'filters',
                into: 'boutique',
                controller: 'boutique'
            });

            //Empreint
            this.render('boutique/produits', {
                outlet: 'produits',
                into: 'boutique',
                controller: 'boutique'
            });

            //Footer
            this.render('boutique/footer', {
                outlet: 'footer',
                into: 'boutique',
                controller: 'boutique'
            });
        }
    });
});