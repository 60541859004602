define("la-grange-a-louis/mixins/sort", ["exports", "ember"], function (exports, _ember) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var asc = "asc";
	var desc = "desc";

	exports.default = _ember.default.Mixin.create({

		queryParams: {
			sort: {
				refreshModel: true,
				as: 'sort'
			},
			direction: {
				refreshModel: true,
				as: 'direction'
			}
		},

		sort: "id",
		direction: undefined,
		actions: {
			sort: function sort(column) {

				if (this.get('sort') === column) {

					if (this.get('direction') === asc) {
						this.set('direction', desc);
					} else {
						this.set('direction', asc);
					}
				} else {
					this.set("sort", column);
					this.set('direction', asc);
				}
			}
		}

	});
});