define('la-grange-a-louis/controllers/admin/produits/index', ['exports', 'ember', 'la-grange-a-louis/mixins/pagination', 'la-grange-a-louis/mixins/sort'], function (exports, _ember, _pagination, _sort) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Controller.extend(_pagination.default, _sort.default, {

		init: function init() {
			this._super();
			_ember.default.run.schedule("afterRender", this, function () {});
		}

	});
});