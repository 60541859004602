define('la-grange-a-louis/adapters/adapterParent', ['exports', 'ember-data', 'la-grange-a-louis/config/environment', 'ember-simple-auth-token/mixins/token-authorizer', 'ember'], function (exports, _emberData, _environment, _tokenAuthorizer, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPIAdapter.extend(_tokenAuthorizer.default, {
		namespace: '',
		host: _environment.default.APP.apiURL,
		session: _ember.default.inject.service(),

		getHeader: function getHeader(header) {
			var authorisation = {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + this.get('session.data.authenticated.access_token')
			};

			return Object.assign(authorisation, header);
		}
	});
});