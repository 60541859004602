define('la-grange-a-louis/routes/admin', ['exports', 'la-grange-a-louis/routes/routeParent'], function (exports, _routeParent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _routeParent.default.extend({

        renderTemplate: function renderTemplate() {
            this._super.apply(this, arguments);

            //Menu
            this.render('admin/menu', {
                outlet: 'menu',
                into: 'admin',
                controller: 'admin'
            });
        }

    });
});