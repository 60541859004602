define('la-grange-a-louis/router', ['exports', 'ember', 'la-grange-a-louis/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('landing-page', { path: '/' });
    this.route('produits', { path: '/produits' });
  });

  /*Router.map(function() {
    this.route('boutique', {path: '/boutique'});
    this.route('admin', function() {
      this.route('produits', function() {
        this.route('ajout');
      });
    });
  });*/

  exports.default = Router;
});