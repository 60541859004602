define('la-grange-a-louis/services/-ea-motion', ['exports', 'ember-animated/services/motion'], function (exports, _motion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _motion.default;
    }
  });
});