define("la-grange-a-louis/helpers/c", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.c = c;
  function c(params /*, hash*/) {

    var str = "";

    for (var i = 0; params.length > i; i++) {
      if (params[i] !== undefined && params[i] !== null) {
        str += params[i];
      }
    }
    return str;
  }

  exports.default = _ember.default.Helper.helper(c);
});