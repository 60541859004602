define('la-grange-a-louis/helpers/htmlsafe', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.htmlsafe = htmlsafe;
	function htmlsafe(params /*, hash*/) {

		if (params[0] !== undefined) {
			return params[0].htmlSafe();
		}

		return;
	}

	exports.default = _ember.default.Helper.helper(htmlsafe);
});