/*import Adapter from './adapterParent';
import $ from 'jquery';

export default Adapter.extend({
	queryRecord(store, type, query) {
		if(query.me){
		    var _this = this;
		    return new Promise(function(resolve, reject) {
		      $.ajax({
		        type: 'POST',
		        url: _this.host+`/me`,
		        dataType: 'json',
		        headers: _this.getHeader(),
		        //data: {}
		      }).then(function(data) {
	       		resolve(data);
		      }, function(data) {
		       	reject(data);
		      });
		    });
		}

		return this._super(...arguments);
	}
});*/
define("la-grange-a-louis/adapters/utilisateur", [], function () {
	"use strict";
});