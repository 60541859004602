define('la-grange-a-louis/controllers/produits', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Controller.extend({
		page_produits: true,
		toggle_status: undefined,
		nav: undefined,
		list: "list-inline",
		blockHeader: false,
		items: [],
		init: function init() {

			var _this = this;
			_ember.default.$(window).scroll(function () {
				_this.toogleHeader();
			});

			var results = [];

			var file = '/assets/csv/produits.json';

			_ember.default.run.schedule("afterRender", this, function () {

				$.ajax({
					url: file,
					type: 'GET',
					cache: false,
					success: function success(resp) {
						//	for(var i in resp){
						for (var i = 0; i < resp.length; i++) {

							var tmp = resp[i]['jours'];
							var photo = resp[i]['photo'];

							if (tmp !== undefined && typeof tmp === 'string') {
								resp[i]['jours'] = tmp.split(' ');
							} else {
								resp[i]['jours'] = [];
							}

							resp[i]['photo'] = _ember.default.String.htmlSafe("background-image: url('/assets/images/produits/IMG_" + photo + ".jpg');");
						}

						_this.set('items', resp);
					}
				});
			});
			/*csv.from.path('../assets/csv/produits.csv').to.array(function (data) {
       for (var index = 0; index < data.length; index++) {
           console.log(data[index][0], data[index][1], data[index][2]);
       }
       //console.log(MyData);
   }); */
		},

		actions: {
			showModalCommande: function showModalCommande() {
				this.set("modal-commande", true);
			},
			toogleMenu: function toogleMenu() {
				this.toggleNavigation();
			},

			scroolNavigation: function scroolNavigation() {
				var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;


				var _this = this;
				this.set('blockHeader', true);
				this.reduceHeader(function () {

					var target = event.target.getAttribute('href');
					var scroll = _ember.default.$(target).offset().top - _ember.default.$("header").height();

					_ember.default.$('html, body').stop().animate({ scrollTop: scroll }, 1000, function () {
						_this.set('blockHeader', false);
					});
				});

				if (this.get('nav') === 'open') {
					this.toggleNavigation();
					this.set('toggle_status', undefined);
				}
				event.preventDefault();
			}
		},

		toggleNavigation: function toggleNavigation() {

			this.set('list', this.get("list") === "slide-out" ? "list-inline" : "slide-out");
			this.set('nav', this.get("nav") === undefined ? "open" : undefined);
		},

		toogleHeader: function toogleHeader() {
			if (this.get('blockHeader') === false) {

				if (_ember.default.$(".header_normal").length && _ember.default.$(document).scrollTop() >= _ember.default.$(".section-1").height() - _ember.default.$("header").height()) {
					this.reduceHeader();
				} else if (_ember.default.$(".header_mini").length && _ember.default.$(document).scrollTop() <= _ember.default.$(".section-1").height() - _ember.default.$("header").height()) {
					this.extendHeader();
				}
			}
		},

		extendHeader: function extendHeader() {
			var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

			_ember.default.$(".header_mini").addClass("header_normal");
			_ember.default.$(".header_normal").removeClass("header_mini");
			if (callback) {
				callback();
			}
		},
		reduceHeader: function reduceHeader() {
			var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

			_ember.default.$(".header_normal").addClass("header_mini");
			_ember.default.$(".header_mini").removeClass("header_normal");
			if (callback) {
				callback();
			}
		},

		myIcon: {
			url: "/assets/images/bread-marker.svg"
			/* size: new google.maps.Size(70,70),
    scaledSize: new google.maps.Size(70,70),
    anchor: new google.maps.Point(35, 70),
    origin: new google.maps.Point(0, 0),
    labelOrigin: new google.maps.Point(30, 15),*/
		},
		customOptions: {
			mapTypeId: 'terrain'
		}

	});
});