define('la-grange-a-louis/helpers/eq', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.eq = eq;
	function eq(params /*, hash*/) {

		if (params[0] && params[1] && params[0] === params[1]) {
			return true;
		}

		return false;
	}

	exports.default = _ember.default.Helper.helper(eq);
});