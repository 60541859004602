define('la-grange-a-louis/initializers/i18n', ['exports'], function (exports) {
        'use strict';

        Object.defineProperty(exports, "__esModule", {
                value: true
        });
        exports.initialize = initialize;
        function initialize(app) {
                app.inject('route', 'i18n', 'service:i18n');
                app.inject('controller', 'i18n', 'service:i18n');
                return app;
        }
});