define('la-grange-a-louis/templates/components/g-map/marker', ['exports', 'ember-google-maps/templates/components/g-map/marker'], function (exports, _marker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _marker.default;
    }
  });
});