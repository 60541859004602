define('la-grange-a-louis/services/messages', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Service.extend({
		duration: 4000,
		flashMessages: _ember.default.inject.service(),

		error: function error(message) {
			var flashMessages = this.get('flashMessages');
			flashMessages.danger("Erreur : " + message, {
				timeout: this.duration,
				destroyOnClick: false
			});
		},
		success: function success(message) {
			var flashMessages = this.get('flashMessages');
			flashMessages.success("Succès : " + message, {
				timeout: this.duration,
				destroyOnClick: false
			});
		}
	});
});