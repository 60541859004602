define('la-grange-a-louis/routes/landing-page', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        renderTemplate: function renderTemplate() {
            this._super.apply(this, arguments);

            //Menu
            this.render('landing-page/menu', {
                outlet: 'menu',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Header
            this.render('landing-page/header', {
                outlet: 'header',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Empreint
            this.render('landing-page/section-empreinte', {
                outlet: 'section-empreinte',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Air de Tradition
            this.render('landing-page/section-air-de-tradition', {
                outlet: 'section-air-de-tradition',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Nos Engagement
            this.render('landing-page/section-nos-engagements', {
                outlet: 'section-nos-engagements',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Galerie
            this.render('landing-page/section-galerie', {
                outlet: 'section-galerie',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Savoir Faire
            this.render('landing-page/section-savoir-faire', {
                outlet: 'section-savoir-faire',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Map
            this.render('landing-page/map', {
                outlet: 'map',
                into: 'landing-page',
                controller: 'landing-page'
            });

            //Footer
            this.render('landing-page/footer', {
                outlet: 'footer',
                into: 'landing-page',
                controller: 'landing-page'
            });
        }
    });
});