define('la-grange-a-louis/components/nav-button', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({
		toggle_status: undefined,
		class: undefined,
		actions: {
			toggle: function toggle() {
				this.set('toggle_status', this.get("toggle_status") === undefined ? this.get("class") : undefined);
				this.sendAction('action');
			}
		}

	});
});