define('la-grange-a-louis/helpers/g-map/compute', ['exports', 'ember-google-maps/helpers/g-map/compute'], function (exports, _compute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _compute.default;
    }
  });
  Object.defineProperty(exports, 'gMapCompute', {
    enumerable: true,
    get: function () {
      return _compute.gMapCompute;
    }
  });
});