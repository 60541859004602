define('la-grange-a-louis/models/four', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    nom: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    taille: _emberData.default.attr('number'),

    displayName: _ember.default.computed('nom', function () {
      return this.get('nom');
    }),

    //Relations
    produits: _emberData.default.hasMany('produit')
  });
});