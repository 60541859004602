define("la-grange-a-louis/routes/routeParent", ["exports", "ember"], function (exports, _ember) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Route.extend({

		loader: _ember.default.inject.service("loader"),

		beforeModel: function beforeModel() /*transition */{
			this._super.apply(this, arguments);
			this.get("loader").show();
		},
		afterModel: function afterModel() /*model, transition*/{
			this._super.apply(this, arguments);
			this.get("loader").hide();
		},


		actions: {
			queryParamsDidChange: function queryParamsDidChange() /*changed, totalPresent, removed*/{
				this.refresh();
			}
		}
	});
});