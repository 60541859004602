define('la-grange-a-louis/controllers/boutique', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Controller.extend({

		garnitures: _ember.default.A(),
		categories: _ember.default.A(),
		grammages: _ember.default.A(),

		init: function init() {
			var _this = this;
			this._super();
			_ember.default.run.schedule("afterRender", this, function () {
				_this.loadGarnitures();
				_this.loadCategories();
				_this.loadGrammages();
			});
		},

		loadGarnitures: function loadGarnitures() {
			var _this = this;

			this.store.query('garniture', {}).then(function (garnitures) {
				_this.set('garnitures', garnitures);
			});
		},

		loadCategories: function loadCategories() {
			var _this = this;

			this.store.query('category', {}).then(function (categories) {
				_this.set('categories', categories);
			});
		},

		loadGrammages: function loadGrammages() {
			var _this = this;

			this.store.query('grammage', {}).then(function (grammages) {
				_this.set('grammages', grammages);
			});
		},

		actions: {}

	});
});