define('la-grange-a-louis/components/g-map/directions', ['exports', 'ember-google-maps/components/g-map/directions'], function (exports, _directions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _directions.default;
    }
  });
});