define('la-grange-a-louis/routes/produits', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        renderTemplate: function renderTemplate() {
            this._super.apply(this, arguments);

            //Menu
            this.render('landing-page/menu', {
                outlet: 'menu',
                into: 'produits',
                controller: 'produits'
            });

            //Header
            this.render('landing-page/header', {
                outlet: 'header',
                into: 'produits',
                controller: 'produits'
            });

            //Footer
            this.render('landing-page/footer', {
                outlet: 'footer',
                into: 'produits',
                controller: 'produits'
            });
        }
    });
});