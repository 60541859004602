define('la-grange-a-louis/routes/admin/produits/index', ['exports', 'la-grange-a-louis/routes/routeParent'], function (exports, _routeParent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _routeParent.default.extend({
    model: function model(params) {
      params.include = "labels,type";
      return this.store.query('produit', params);
    }
  });
});