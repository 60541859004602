define("la-grange-a-louis/mixins/authentification", ["exports", "ember"], function (exports, _ember) {
		"use strict";

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = _ember.default.Mixin.create({

				session: _ember.default.inject.service(),
				messages: _ember.default.inject.service("messages"),
				loader: _ember.default.inject.service("loader"),

				utilisateur: undefined,
				init: function init() {
						this._super.apply(this, arguments);

						if (this.get('session.isAuthenticated') === true) {
								this.loadUtilisateur();
						}
				},


				loadUtilisateur: function loadUtilisateur() {
						var _this = this;
						this.get('store').queryRecord('utilisateur', { me: true }).then(function (utilisateur) {
								_this.set('utilisateur', utilisateur);
						});
				},

				actions: {

						authenticate: function authenticate() {

								this.get('loader').show();
								var credentials = this.getProperties('login', 'password');

								credentials.type = 1; //admi
								var authenticator = 'authenticator:jwt';
								var _this = this;
								this.get('session').authenticate(authenticator, credentials).then(function () {

										//Success
										_this.loadUtilisateur();
										_this.get('loader').hide();
										_this.get('messages').success("Vous êtes bien connecté !");
								}, function (err) {

										//Erreur
										_this.get('messages').error(err.json.error);
										_this.get('loader').hide();
								});
						},

						logout: function logout() {
								this.get('session').invalidate();
						}
				}
		});
});