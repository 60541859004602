define("la-grange-a-louis/components/label-produit", ["exports", "ember"], function (exports, _ember) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({
		class: "",
		style: "",
		label: undefined,
		classNameBindings: ['inline:fl'],
		inline: false

	});
});