define('la-grange-a-louis/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPISerializer.extend({
		normalizeQueryResponse: function normalizeQueryResponse(store, clazz, payload) {
			var result = this._super.apply(this, arguments);
			result.meta = result.meta || {};

			if (payload.links && payload.meta.pagination) {
				result.meta.links = payload.links;
				result.meta.pagination = payload.meta.pagination;
			}

			return result;
		}
	});
});