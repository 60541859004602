define('la-grange-a-louis/components/file-upload', ['exports', 'ember-uploader/components/file-field', 'ember'], function (exports, _fileField, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _fileField.default.extend({

		multiple: false,
		images: _ember.default.A(),
		files: undefined,

		readUrls: function readUrls(files) {

			var _this = this;
			if (files && files[0]) {

				var onloadEvent = function onloadEvent(e) {
					_this.get('images').pushObject(e.target.result);
					_this.notifyPropertyChange("images");
				};

				for (var i = 0; i < files.length; i++) {
					var reader = new FileReader();
					reader.onload = onloadEvent;
					reader.readAsDataURL(files[i]);
				}
			}
		},

		filesDidChange: function filesDidChange(files) {
			this.set("files", files);
			this.readUrls(files);
		}
	});
});