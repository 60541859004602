define('la-grange-a-louis/components/table-th', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Component.extend({

		column: undefined,
		sort: undefined,
		direction: undefined,
		class: "",

		tagName: 'th',
		actions: {
			sort: function sort(column) {
				this.onSort(column);
			}
		}
	});
});