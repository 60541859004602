define('la-grange-a-louis/services/loader', ['exports', 'ember'], function (exports, _ember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ember.default.Service.extend({
		visible: false,

		show: function show() {
			this.set('visible', true);
		},
		hide: function hide() {
			this.set('visible', false);
		},


		isVisible: function isVisible() {
			return this.get('visible');
		}

	});
});