define('la-grange-a-louis/adapters/application', ['exports', 'ember-data', 'la-grange-a-louis/config/environment', 'ember-simple-auth-token/mixins/token-authorizer'], function (exports, _emberData, _environment, _tokenAuthorizer) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPIAdapter.extend(_tokenAuthorizer.default, {
		namespace: '',
		host: _environment.default.APP.apiURL
	});
});