define('la-grange-a-louis/utils/public-api', ['exports', 'ember-google-maps/utils/public-api'], function (exports, _publicApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _publicApi.default;
    }
  });
});